import {
  Alert,
  AlertIcon,
  Button,
  ButtonProps,
  CircularProgress,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { miraieFields } from '@terass/common/src'

import { useValidateMutation } from '../MiraieFormApplyButton/useValidateMutation'

import { usePanelImagesLayoutContext } from '@/components/form/ImagesLayout/PanelImagesLayoutContext'
import { useMiraieFormContext, useMiraieFormState } from '@/hooks/useMiraieForm'
import { useUserEmail } from '@/hooks/useUser'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'
import { useV1ImportParam } from '@/hooks/useV1ImportParam'
import { miraieValidate } from '@/utils/functions'
import { updateImport } from '@/utils/import'
import { toast } from '@/utils/standaloneToast'

export const MiraieFormValidateButton = (props: ButtonProps) => {
  const importId = useV1ImportParam()
  const { dirtyFields } = useMiraieFormState()
  const isDirty = useV1ImportDocument(({ isDirty }) => isDirty)
  const { getValues } = useMiraieFormContext()
  const { savedPanelImagesLayout } = usePanelImagesLayoutContext()
  const validateMutation = useValidateMutation(() =>
    updateImportMutation.mutate(),
  )
  const email = useUserEmail()
  const updateImportMutation = useMutation({
    mutationFn: async () => {
      const { draftCloseDates, ...formData } = getValues()

      await updateImport(importId, {
        formData: {
          ...formData,
          ...miraieFields.mngr_open_kbn.getValues(formData),
          portal_staff: formData.portal_staff ?? undefined, // 未設定の場合は送信しない
        },
        draftCloseDates,
        panelImagesLayout: savedPanelImagesLayout,
        updatedBy: email,
        ...(!isDirty &&
          Object.keys(dirtyFields).length > 0 && { isDirty: true }),
      })
    },
    onSuccess: () => miraieValidateMutation.mutate(),
  })
  const miraieValidateMutation = useMutation({
    mutationFn: () => miraieValidate({ importId }),
    onSuccess: () => {
      resetMutations()
      toast({
        description: 'エラーのチェックが完了しました。',
      })
    },
  })
  const mutations = {
    validateMutation,
    updateImportMutation,
    miraieValidateMutation,
  } as const
  const mutationNames = [
    'validateMutation',
    'updateImportMutation',
    'miraieValidateMutation',
  ] as const satisfies (keyof typeof mutations)[]
  const isPending = mutationNames.some(
    (name) => mutations[name].status === 'pending',
  )
  const isProgressOpen = mutationNames.some(
    (name) => mutations[name].status !== 'idle',
  )
  const errorIndex = mutationNames.findIndex(
    (name) => mutations[name].status === 'error',
  )
  const errorMutationName = mutationNames[errorIndex]
  const resetMutations = () =>
    mutationNames.forEach((name) => mutations[name].reset())

  return (
    <>
      <Button
        isLoading={isPending}
        onClick={() => validateMutation.mutate()}
        {...props}
      />
      <Modal
        isOpen={isProgressOpen}
        onClose={resetMutations}
        closeOnEsc={!isPending}
        closeOnOverlayClick={!isPending}
      >
        <ModalOverlay />
        <ModalContent>
          {isPending || <ModalCloseButton />}
          <ModalHeader>
            {isPending ? '処理中...' : '入力内容の検証に失敗しました'}
          </ModalHeader>
          <ModalBody>
            {isPending && (
              <HStack>
                <CircularProgress isIndeterminate size="1rem" />
                <Text>入力内容の検証中...</Text>
              </HStack>
            )}
            {errorMutationName && (
              <Alert status="error">
                <AlertIcon />
                <Text>{mutations[errorMutationName].error?.message}</Text>
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button isLoading={isPending} onClick={resetMutations}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
