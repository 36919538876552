import type {
  FetchMediaPublishedRequestFutureForRent,
  FetchMediationContract,
  FetchMediationContractById,
  FetchTeraThreeAgent,
  FetchTeraThreeAgents,
  GetServerTimestamp,
  PostShisetsu,
  MiraieValidate,
  PublishMediaCoverage,
  FetchMediaPublishRequestForTeraSevens,
  FetchSakimonoMediaPublishRequestForTeraSevens,
  FetchContractByCaseId,
  IsExistsV1Import,
} from '@terass/common/src/functions'
import { httpsCallable } from 'firebase/functions'

import { functions } from './firebase'

export const fetchMediationContract = () =>
  httpsCallable<never, Awaited<ReturnType<FetchMediationContract>>>(
    functions,
    'fetchMediationContractFunction',
  )().then(({ data }) => data)

export const fetchMediationContractById = (id: string) =>
  httpsCallable<
    Parameters<FetchMediationContractById>[0],
    Awaited<ReturnType<FetchMediationContractById>>
  >(
    functions,
    'fetchMediationContractByIdFunction',
  )(id).then(({ data }) => data)

export const fetchMediaPublishRequestForTeraSevens: FetchMediaPublishRequestForTeraSevens =
  (params) =>
    httpsCallable<
      Parameters<FetchMediaPublishRequestForTeraSevens>[0],
      Awaited<ReturnType<FetchMediaPublishRequestForTeraSevens>>
    >(
      functions,
      'fetchMediaPublishRequestForTeraSevens',
    )(params).then(({ data }) => data)

export const fetchContractByCaseId = (caseId: string) =>
  httpsCallable<
    Parameters<FetchContractByCaseId>[0],
    Awaited<ReturnType<FetchContractByCaseId>>
  >(
    functions,
    'fetchContractByCaseId',
  )(caseId).then(({ data }) => data)

export const fetchSakimonoMediaPublishRequestForTeraSevens: FetchSakimonoMediaPublishRequestForTeraSevens =
  (params) =>
    httpsCallable<
      Parameters<FetchSakimonoMediaPublishRequestForTeraSevens>[0],
      Awaited<ReturnType<FetchSakimonoMediaPublishRequestForTeraSevens>>
    >(
      functions,
      'fetchSakimonoMediaPublishRequestForTeraSevens',
    )(params).then(({ data }) => data)

export const fetchMediaPublishedRequestFutureForRent = () =>
  httpsCallable<
    never,
    Awaited<ReturnType<FetchMediaPublishedRequestFutureForRent>>
  >(functions, 'fetchMediaPublishedRequestFutureForRent')().then(
    ({ data }) => data,
  )

export const fetchTeraThreeAgents = () =>
  httpsCallable<never, Awaited<ReturnType<FetchTeraThreeAgents>>>(
    functions,
    'fetchTeraThreeAgents',
  )().then(({ data }) => data)

export const fetchAgent: FetchTeraThreeAgent = (params) =>
  httpsCallable<
    Parameters<FetchTeraThreeAgent>[0],
    Awaited<ReturnType<FetchTeraThreeAgent>>
  >(
    functions,
    'fetchTeraThreeAgent',
  )(params).then(({ data }) => data)

export const publishMediaCoverage: PublishMediaCoverage = (params) =>
  httpsCallable<
    Parameters<PublishMediaCoverage>[0],
    Awaited<ReturnType<PublishMediaCoverage>>
  >(
    functions,
    'publishMediaCoverage',
  )(params).then(({ data }) => data)

export const getServerTimestamp: GetServerTimestamp = () =>
  httpsCallable<never, number>(functions, 'getServerTimestamp')().then(
    ({ data }) => data,
  )

export const postShisetsu: PostShisetsu = (params) =>
  httpsCallable<Parameters<PostShisetsu>[0], void>(
    functions,
    'postShisetsu',
  )(params).then(({ data }) => data)

export const miraieValidate: MiraieValidate = (params) =>
  httpsCallable<
    Parameters<PublishMediaCoverage>[0],
    Awaited<ReturnType<PublishMediaCoverage>>
  >(
    functions,
    'miraieValidate',
  )(params).then(({ data }) => data)

export const isExistsV1Import: IsExistsV1Import = (params) =>
  httpsCallable<
    Parameters<IsExistsV1Import>[0],
    Awaited<ReturnType<IsExistsV1Import>>
  >(
    functions,
    'isExistsV1Import',
  )(params).then(({ data }) => data)
